import request from '@/utils/request'
// 试用列表
export function listAllApplys (data) {
  return request({
    url: '/tryout/listAllApplys',
    method: 'POST',
    data
  })
}
//
export function updateApplyResult (data) {
  return request({
    url: '/tryout/updateApplyResult',
    method: 'POST',
    data
  })
}
