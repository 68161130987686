<template>
  <section class="try-out-list">
    <section class="filter">
      <el-form :inline="true" :model="form">
        <el-form-item label="时间">
          <el-date-picker
                v-model="form.time"
                type="date"
                size="small"
                placeholder="选择日期" value-format="yyyy-MM-dd">
              </el-date-picker>
          <!-- <el-select v-model="form.time" placeholder="">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item label="公司名称">
          <el-input v-model="form.name" size="small" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="form.status" size="small">
            <el-option label="全部" ></el-option>
            <el-option label="已处理" value=1></el-option>
            <el-option label="不处理" value=2></el-option>
            <el-option label="未处理" value=0></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="conditionSelect">查询</el-button>
        </el-form-item>
      </el-form>
    </section>
    <section class="content" v-loading="loading">
      <div class="control">
<!--        <el-button size="mini" type="primary" @click="handleAdd">创建</el-button>-->
<!--        <el-button size="mini" type="primary" @click="handleEdit">编辑</el-button>-->
<!--        <el-button size="mini" type="primary" @click="handleRemove">删除</el-button>-->

        <el-button size="mini" type="primary" @click="dealWith">处理</el-button>
        <el-button size="mini" type="primary" @click="nodealWith">不处理</el-button>
      </div>
      <el-table
        :data="tableData"
        border max-height="calc(100% - 40px)"
        style="width: 100%"
        @selection-change="handleSelectionChange">
        <el-table-column  :resizable="false"
          type="selection"
          width="39"
          align="center">
        </el-table-column>
        <el-table-column  :resizable="false"
          prop="contactPerson"
          label="联系人"
          align="center"
          width="140">
        </el-table-column>
        <el-table-column  :resizable="false"
          prop="phone"
          align="center"
          label="联系方式">
        </el-table-column>
        <el-table-column  :resizable="false"
          prop="companyName"
          label="公司名称"
          align="center"
          width="140">
        </el-table-column>
        <el-table-column  :resizable="false"
          prop="createTime"
          align="center"
          width="200"
          label="时间">
        </el-table-column>
<!--        <el-table-column  :resizable="false"-->
<!--          prop="status"-->
<!--          align="center"-->
<!--          width="120"-->
<!--          label="状态">-->
<!--        </el-table-column>-->
        <el-table-column  :resizable="false" prop="status" align="center" label="状态">
          <template scope="{ row }">
            <span v-if="row.status == 0">未处理</span>
            <span v-else-if="row.status == 1" >已处理</span>
          </template>
        </el-table-column>
      </el-table>
      <section class="list-bottom">
        <span class="count">共{{count}}条记录</span>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10 , 20, 30, 50]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="count">
        </el-pagination>
      </section>
      <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        top="30vh"
        width="30%"
        :before-close="handleClose" center>
        <span class="prompt"><i class="el-icon-warning"></i>确定删除？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
    </section>
  </section>
</template>

<script>
import { listAllApplys, updateApplyResult } from '@/api/tryOut.js'
export default {
  data () {
    return ({
      form: {
        time: '',
        name: '',
        status: ''
      },
      activeName: 'first',
      tableData: [],
      count: '',
      currentPage: 1,
      size: 10,
      dialogVisible: false,
      multipleSelection: [],
      selectionId: [],
      loading: false
    })
  },
  mounted () {
    this.viewListAllApplys()
  },
  methods: {
    // 不处理
    async dealWith () {
      if (this.selectionId.length === 0) {
        this.$message.error('请选中一条数据')
        return false
      }
      const res = await updateApplyResult({ id: this.selectionId, status: 1 })
      if (res.code === 200) {
        this.$message({
          message: '设置成功',
          type: 'success'
        })
      } else {
        this.$message.error('设置失败')
      }
      this.viewListAllApplys()
      console.log(res)
    },
    // 不处理
    async nodealWith () {
      if (this.selectionId.length === 0) {
        this.$message.error('请选中一条数据')
        return false
      }
      const res = await updateApplyResult({ id: this.selectionId, status: 2 })
      if (res.code === 200) {
        this.$message({
          message: '设置成功',
          type: 'success'
        })
      } else {
        this.$message.error('设置失败')
      }
      console.log(res)
      this.viewListAllApplys()
    },
    handleSizeChange (val) {
      this.limit = val
      this.viewListAllApplys(val, this.currentPage)
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.viewListAllApplys(this.limit, val)
      console.log(`当前页: ${val}`)
    },
    async conditionSelect () {
      const res = await listAllApplys({ pageSize: this.size, current: this.currentPage, createTime: this.form.time, status: this.form.status, companyName: this.form.name })
      this.tableData = res.data
      this.count = res.count
    },
    async viewListAllApplys () {
      this.loading = true
      const res = await listAllApplys({ pageSize: this.size, current: this.currentPage })
      this.tableData = res.data
      this.count = res.count
      this.loading = false
    },
    handleClick (tab, event) {
      console.log(tab, event)
    },
    handleSelectionChange (val) {
      console.log(val)
      this.selectionId = []
      this.multipleSelection = val
      this.multipleSelection.forEach(item => {
        this.selectionId.push(item.id)
      })
    },
    handleAdd () {
      this.$router.push('/tryOut/add')
    },
    handleEdit () {
      if (this.selectionId.length === 0) {
        this.$message.error('请选中一条数据')
        return false
      }
      this.$router.push(`/tryOut/edit/${this.selectionId[0]}`)
    },
    handleClose () {
      this.dialogVisible = false
    },
    handleRemove () {
      if (this.selectionId.length === 0) {
        this.$message.error('请选中一条数据')
        return false
      }
      this.dialogVisible = true
    }
  }
}
</script>

<style lang="scss">
.try-out-list {
  width: 100%;
  height: 100%;
  .filter {
    width: 100%;
    height: 70px;
    background: #fff;
    border-radius: 2px;
    padding-top: 15px;
    padding-left: 20px;
    box-shadow: 0px 0px 6px 0px rgba(53, 64, 82, 0.06);
    .el-form {
      .el-form-item {
        margin-right: 20px;
        /* &:nth-last-child(2) {
          margin-right: 30px;
        } */
      }
    }
  }
  .content {
    width: 100%;
    min-height: calc(100% - 90px);
    margin-top: 20px;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0px 0px 6px 0px rgba(53, 64, 82, 0.06);
    padding: 20px 30px;
    position: relative;
    .control {
      margin-bottom: 25px;
    }
    .el-table {
      margin-bottom: 50px;
    }
    .list-bottom {
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: 20px;
      left: 20px;
      right: 20px;
      .count {
        line-height: 32px;
      }
    }
  }
}
</style>
